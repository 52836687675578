import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        userAcc: '',
        userId: '',
        externalId: '',
        countMsg: {
            read: 0,
            unRead: 0
        },
        headerTips: `1月10起，各厂家将陆续停止发货，请有采购需求的同事尽早规划，及时付款下单！`,
        headerTips2: `每日一点，助力排名！动动小手，点击进入下方链接哦`
    },
    mutations: {
        setAcc(state, acc){
            state.userAcc = acc
        },
        setId(state, {userId, externalId}){
            state.userId = userId
            state.externalId = externalId
        },
        setMsg(state, data){
            state.countMsg = data
        }
    },
    modules: {
        common
    },
})


export default store